<script lang="ts">
import { Splide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import CardInfo from '@/Components/CardInfo.vue';

export default {
    name: "CardInfoContainer",
    components: {
        Splide, SplideTrack,CardInfo 
    },
    data() {
        return {
            slickOptions: {
                slidesToShow: this.slidesToShow,           
            },

            currentSlide:  0 

        };
    },
    props: {
        title: {type: String, default: ""},
        titleClass: {type: String, default: "font-home-title"},
        titleAnimationClass: {type: String, default: "fade-right"},
        tipo: {type: String, default: 'slide'},
        breakpoint: {type: Number, default: null},
        arrowId: {type: String, default: ''},
        customArrowClass : {type: String, default: ''},
        gap: {type: String, default: '6rem'},
        container: {type: Boolean, default: true},
        arrowClasses: {type: String, default: ''},
        pages: {type: Number, default: 3},
        width: {type: String, default: '100%'},
        arrows: {type: Boolean, default: true},
    },
    methods: {
        checkArrowState(){
            const arrows = document.getElementById(this.arrowId)
            if(!arrows || !this.customArrowClass || !this.breakpoint)return;
            window.innerWidth >= this.breakpoint && arrows.classList.add(this.customArrowClass);
        }
    },
    mounted() {
        this.checkArrowState();
    },

}
</script>

<template>

<div class="gap-[2rem]" :class="{'ctn home-container': container}">
    <Splide     
    ref="carousel" 
    :hasTrack="false" 
    :options="{
        arrows: arrows,
        rewind: false,
        perPage: pages,
        type: tipo,
        speed: 700,
        width: width,
        pagination: false,
        breakpoints: {
            1279: {
                perPage: 2,
             },
             630: {
                perPage: 1,
             }
        },
        gap: gap,
        autoWidth: true,
    }">
        <div class="flex justify-between" :class="arrowClasses">
            <div class="how-to-title flex flex-row">
                <h2 :class="`${titleClass}`" :data-aos="titleAnimationClass">{{ title }}</h2>
            </div>  
            <div class="splide__arrows" :id="arrowId">
                <button class="splide__arrow splide__arrow--prev">
                    <i class="fas fa-angle-left font-title"></i>
                </button>
                <button class="splide__arrow splide__arrow--next">
                    <i class="fas fa-angle-right font-title"></i>
                </button>
            </div>
        </div>
        <SplideTrack >
            <slot/>
        </SplideTrack>
    </Splide>


</div>
</template>

<style scoped>

.splide__track {
    overflow: visible !important;
}
.splide__arrows{
    display: flex;
    align-items: center;
    gap: 5rem;
}
.splide__arrows.hide{
    display: none;
}
.splide__arrow{
    position: inherit;
    background-color: transparent;
    transform: none;
    width: auto;
}


</style>